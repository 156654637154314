exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-care-work-types-index-tsx": () => import("./../../../src/pages/care-work-types/index.tsx" /* webpackChunkName: "component---src-pages-care-work-types-index-tsx" */),
  "component---src-pages-career-paths-in-care-work-index-tsx": () => import("./../../../src/pages/career-paths-in-care-work/index.tsx" /* webpackChunkName: "component---src-pages-career-paths-in-care-work-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interview-01-index-tsx": () => import("./../../../src/pages/interview/01/index.tsx" /* webpackChunkName: "component---src-pages-interview-01-index-tsx" */),
  "component---src-pages-interview-02-index-tsx": () => import("./../../../src/pages/interview/02/index.tsx" /* webpackChunkName: "component---src-pages-interview-02-index-tsx" */),
  "component---src-pages-interview-03-index-tsx": () => import("./../../../src/pages/interview/03/index.tsx" /* webpackChunkName: "component---src-pages-interview-03-index-tsx" */),
  "component---src-pages-interview-04-index-tsx": () => import("./../../../src/pages/interview/04/index.tsx" /* webpackChunkName: "component---src-pages-interview-04-index-tsx" */),
  "component---src-pages-interview-05-index-tsx": () => import("./../../../src/pages/interview/05/index.tsx" /* webpackChunkName: "component---src-pages-interview-05-index-tsx" */),
  "component---src-pages-interview-06-index-tsx": () => import("./../../../src/pages/interview/06/index.tsx" /* webpackChunkName: "component---src-pages-interview-06-index-tsx" */),
  "component---src-pages-interview-07-index-tsx": () => import("./../../../src/pages/interview/07/index.tsx" /* webpackChunkName: "component---src-pages-interview-07-index-tsx" */),
  "component---src-pages-interview-08-index-tsx": () => import("./../../../src/pages/interview/08/index.tsx" /* webpackChunkName: "component---src-pages-interview-08-index-tsx" */),
  "component---src-pages-interview-09-index-tsx": () => import("./../../../src/pages/interview/09/index.tsx" /* webpackChunkName: "component---src-pages-interview-09-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */)
}

